import Coastal from "../assets/RTD/coastal.png";
import Coppercan from "../assets/RTD/coppercan.png";
import Wilder from "../assets/RTD/wilder.png";

export const RTD = [
  {
    img: Coastal,
    url: "https://coastalcocktailsva.com/",
    title: 'Coastal Cocktails',
    tags: ['ready to drink']
  },
  {
    img: Coppercan,
    url: "https://www.thecoppercan.com/",
    title: 'The Copper Can',
    tags: ['ready to drink']
  },
  {
    img: Wilder,
    url: "https://www.wilderwhiskey.com/",
    title: 'Wilder Whiskey',
    tags: ['ready to drink']
  },
];
